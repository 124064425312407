<template>
    <v-card
        :loading="loading"
        class="gt-card"
    >
        <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>

        <v-card-title class="gt-card__title">{{ gig.title }}</v-card-title>

        <v-img
            class="gt-card__img"
            aspect-ratio="1"
            src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
        ></v-img>

        <v-card-text class="gt-card__text">
            <p>{{ localeDate }}<br>{{ localeTime }}</p>

            <p>
                <router-link class="gt-card__link" :to="{name: 'venue.public', params: {slug: venue.slug}}">
                    {{ venue.name }}
                </router-link>
                <br>
                {{ city.name }}, <br>
                {{ city.state_code }}
            </p>
            <p class="font-italic">Featuring:</p>
            <v-row>
                <v-col sm="auto">
                    <v-img
                        width="110"
                        aspect-ratio="1"
                        :src="artist.avatar_url"
                    ></v-img>
                </v-col>
                <v-col class="align-self stretch d-flex flex-column justify-space-between">
                    <div>
                        <router-link class="gt-card__link" :to="{name: 'artist.public', params: {slug: artist.slug}}">
                            {{ artist.name }}
                        </router-link>
                    </div>
                    <div>{{ $_.startCase(artist.type) }}</div>
                </v-col>
            </v-row>
        </v-card-text>


    </v-card>
</template>

<script>
export default {
    name: "GigCard",
    props: ['gig'],
    data: function () {
        return {
            loading: false,
            artist: this.gig.artist,
            venue: this.gig.venue,
            city: this.gig.city,
        }
    },
    computed: {
        localeDate() {
            return (new Date(this.gig.starts_at)).toLocaleDateString(
                'en-US',
                {weekday: 'short', month: 'short', day: 'numeric', year: 'numeric'}
            )
        },
        localeTime() {
            return (new Date(this.gig.starts_at)).toLocaleTimeString(
                'en-US',
                {hour: '2-digit', minute: '2-digit'}
            )
        },
    },
    methods: {
        async toggleFavorite() {
            await window.axios.post('/api/favorites', {type: 'gig', id: this.artist.id})
            await this.$auth.fetch()
        }
    }
}
</script>

<style scoped>

</style>
