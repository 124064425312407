<template>
    <front-layout class="page--events">
        <v-container>
            <header class="main__header">
                <h1>Featured Events</h1>
                <p>Here’s what’s going on near you, every day of the week.</p>
            </header>
        </v-container>
        <div class="page__search-form">
            <v-container>
                <v-row class="flex-column flex-sm-row">
                    <v-col>
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    label="Date"
                                    prepend-inner-icon="mdi-calendar"
                                    clearable
                                    hide-details="auto"
                                    readonly
                                    dense solo flat
                                    class="rounded-0"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date"
                                @input="menu2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <city-selector
                            return-object
                            v-model="city"
                            dense solo flat
                            clearable
                            hide-details="auto"
                            prepend-inner-icon="mdi-map-marker"
                            class="rounded-0"
                        />
                    </v-col>
                    <v-col sm="12" md="4">
                        <v-text-field
                            v-model="title"
                            clearable
                            hide-details="auto"
                            dense solo flat
                            class="rounded-0"
                            label="Search for Events or Destinations"
                            prepend-inner-icon="mdi-magnify"
                            />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="page__content">
            <v-row>
                <v-col v-for="event in featuredEvents" :key="`event`+event.id">
                    <gig-card :gig="event"/>
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import Gig from "@/models/Gig";
import GigCard from "@/components/cards/GigCard";
import CitySelector from "@/components/CitySelector";

export default {
    name: "Index",
    components: {CitySelector, GigCard, FrontLayout},
    computed: {
        featuredEvents() {
            return this.gigs
        }
    },
    data: function () {
        return {
            gigs: [],
            date: null,
            city: null,
            title: null,
            menu: false,
        }
    },
    methods: {
       async fetchGigs() {
           let query = Gig.custom('gigs/public').limit(6)
           if (this.date) {
               query = query.where('starts_after', this.date)
           }
           if (this.city) {
               query = query.where('city_id', this.city.id)
           }
           if (this.title) {
               query = query.where('title', this.title)
           }

           query.include(['artist', 'venue'])

           this.gigs = await query.get()
       }
    },
    mounted() {
        this.fetchGigs()
    },
    watch: {
        date: function (val, oldval) {
            if (val !== oldval)  {
                this.fetchGigs()
            }
        },
        city: function (val, oldval) {
            if (val !== oldval)  {
                this.fetchGigs()
            }
        },
        title: function (val, oldval) {
            if (val !== oldval)  {
                this.fetchGigs()
            }
        },
    }
}
</script>

<style scoped>

</style>
